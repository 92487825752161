import React, { useState } from "react";
import imgLogo from "../../assets/logo/cig3d.webp";
import axios from "axios";
import ContactForm from "../../components/ContactForm";

function ContactFormCollaboratori() {
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [newsLetterCheck, setNewsLetterCheck] = useState(false);

  const getIdOrigin = () => {
    // Try to get 'IdOrigin' from local storage
    let idOrigin = localStorage.getItem('IdOrigin');

    // If 'IdOrigin' is not found in local storage, try cookies
    if (idOrigin === null) {
      const cookies = document.cookie.split('; ');
      for (const cookie of cookies) {
        const [name, value] = cookie.split('=');
        if (name === 'IdOrigin') {
          idOrigin = value;
          break;
        }
      }

      // If 'IdOrigin' is not found in cookies, try session storage
      if (idOrigin === null) {
        idOrigin = sessionStorage.getItem('IdOrigin');
      }
    }

    return idOrigin;
  };

  const newsletterToggle = () => {
    if (newsLetterCheck === false) {
      setNewsLetterCheck(true);
    } else {
      setNewsLetterCheck(false);
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const goToCollaboraConNoi = () => {
    if (selectedOption === "0") {
      setShowModal(false);
      window.location.href = "https://4changeyourbusiness.it/";
    } else if (selectedOption === "1") {
      setShowModal(false);
      window.location.href =
        "https://www.careisgold.it/collabora-con-noi?activeTab=1";
    } else {
      alert("Please select an option ", error);
    }
  };

  const reload = () => {
    setShowModal(false);
    window.location.reload();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!submitted) {
      const vnomeCognome = document.getElementById("nomeCognome").value;
      const vemail = document.getElementById("email").value;
      const vtelefono = document.getElementById("telefono").value;
      const vfasciaLivello = document.getElementById("fasciaLivello").value;
      const vmessaggio = document.getElementById("messaggio").value;
      const vidProvenienzaConsenso = 12;
      const idOrigineCliente = getIdOrigin();


      setSubmitted(true);

      try {
        const response = await fetch(
          "https://newgest.careholding.it/ws.asmx/insertPrivacyLandingPageTRAM",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              nomeCognome: vnomeCognome,
              email: vemail,
              telefono: vtelefono,
              fasciaLivello: vfasciaLivello,
              messaggio: vmessaggio,
              idprovenienzaconsenso: vidProvenienzaConsenso,
              idOrigine: idOrigineCliente,
            }),
          }
        );

        if (response.ok) {
          setSubmitted(true);
          setShowModal(true);
          setError(false);
          // send to sending blu
          if (newsLetterCheck) {

            let LIST_ID = 0;

            if(vfasciaLivello === "0"){
              LIST_ID = 15;
            }else if(vfasciaLivello === "1"){
              LIST_ID = 51;
            }

            const API_KEY =
              "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";

            const email = vemail;

            const addContactToList = async (email) => {
              const url = `https://api.brevo.com/v3/contacts`;
              const data = {
                email,
                listIds: [LIST_ID],
              };
              const headers = {
                "api-key": API_KEY,
                "Content-Type": "application/json",
              };

              try {
                const response = await axios.post(url, data, { headers });
                console.log(response.data);
              } catch (error) {
                if (error.response && error.response.status === 400) {
                  const brevoUrl = `https://api.brevo.com/v3/contacts/lists/${LIST_ID}/contacts/add`;
                  const brevoData = {
                    emails: [`${email}`],
                  };
                  const brevoHeaders = {
                    "api-key": API_KEY,
                    "Content-Type": "application/json",
                  };
                  try {
                    const response = await axios.post(brevoUrl, brevoData, {
                      headers: brevoHeaders,
                    });
                    console.log(response.data);
                  } catch (brevoError) {
                    console.error(brevoError);
                  }
                } else {
                  console.error(error);
                }
              }
            };

            addContactToList(email);
          }
        } else {
          setSubmitted(true);
          setError(true);
          setShowModal(true);
        }
      } catch (error) {
        console.error("Error:", error);
        setSubmitted(false);
        setError(true);
      }
    }
  };

  return (
    <>
      <div className="background-collaboratori-2">
        <div className="container-fluid bg-dark bg-opacity-50">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-4 col-md-2 mt-3">
              <img src={imgLogo} alt="logo" className="img-fluid" />
            </div>
            <div className="col-12 text-center mt-3">
              <p className="text-default fs-1 m-0">Collabora con noi</p>
            </div>
          </div>
        </div>
        <div
          className="container-fluid bg-dark bg-opacity-50"
          id="contact-form"
        >
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-12 col-md-8 col-xl-7 col-xxl-4 text-center">
            <ContactForm 
                NomeLanding='Landing Page TRAM'
                Tipo='Collaboratore'
                BaseInputs={[    
                    { name: 'nomeCognome', required: true},
                    { name: 'email', required: true},
                    { name: 'telefono', required: true},
                ]}
                City={[{
                     city: true, 
                     required: true,
                     cityName: 'Città',
                     placeholder: 'Città'
                }]}
                DropDown={[{
                    dropDown: true, 
                    title:"Livello di partenza", 
                    defaultOption: "Scegli un'opzione", 
                    disabled: true, 
                    option1: 'Senza esperienza',
                    option2: 'Professionista',
                    option3: '',
                    required: true
                }]}
                Vote={[{
                    vote: false, 
                    name: "Quanto ti è piaciuto l'evento?", 
                    message: true, 
                    placeholder: 'Raccontaci la tua esperienza',
                    required: false
                }]}
                Message={[{
                    message: true, 
                    required: false,
                    messageName: 'Messaggio',
                    placeholder: 'altro...'
                }]}
                ConsensoDati={[{
                    consensoDati: true, 
                    required: true,
                    idPrivacy: 1
                }]}
                ConsensoNewsletter={[{
                    consensoNewsletter: false, 
                    listId: 'private',
                    required: false,
                    idPrivacy: 3
                }]}
                SubmitButton={[{
                    submitName:'invia', 
                    submittedName:'inviato',
                    submittingName:'Sto inviando...'
                }]}
                SuccessMessage={[{
                    successMessage:'La tua richiesta è stata inoltrata con successo!', 
                    successButton:'Visita il nostro sito!', 
                    url:'https://www.careisgold.it/'
                }]}
            />
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactFormCollaboratori;
