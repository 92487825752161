import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

function Tolstoy() {

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1200);

  useEffect(() => {
    // Define your Tolstoy app key
    window.tolstoyAppKey = '975780b8-85be-4fde-876f-0043e119c7f3';

    // Function to handle window resize and update isWideScreen state
    const handleWindowResize = () => {
      setIsWideScreen(window.innerWidth > 576);
    };

    // Attach a resize event listener
    window.addEventListener('resize', handleWindowResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // useEffect(() => {
  //   const element = document.querySelector('.iubenda-tp-btn[data-tp-float="bottom-left"]');

  //   if (element) {
  //     element.setAttribute('data-tp-float', 'top-right');
  //   }
  // }, []);

return (
    <>
    <div>
        <Helmet>
            <title>Careisgold SpA: Golden Tram</title>
            <script>tolstoyAppKey="975780b8-85be-4fde-876f-0043e119c7f3"</script>
            <script src="https://widget.gotolstoy.com/widget/widget.js" defer></script>
            <script type="text/javascript" id="iubScript">
            {`
              var _iub = _iub || [];
              _iub.csConfiguration = {
                consentOnContinuedBrowsing: false,
                countryDetection: true,
                enableCcpa: true,
                floatingPreferencesButtonIcon:false,
                invalidateConsentWithoutLog: true,
                perPurposeConsent: true,
                siteId: 2467757,
                whitelabel: false,
                cookiePolicyId: 95086304,
                lang: "it",
                banner: {
                    acceptButtonCaptionColor: "white",
                    acceptButtonColor: "#DBBE6F",
                    acceptButtonDisplay: true,
                    backgroundColor: "white",
                    brandBackgroundColor: "#FFFFFF",
                    brandTextColor: "#000000",
                    closeButtonRejects: true,
                    customizeButtonCaptionColor: "#4D4D4D",
                    customizeButtonColor: "#DBBE6F",
                    customizeButtonDisplay: true,
                    explicitWithdrawal: true,
                    listPurposes: true,
                    logo: "https://www.careisgold.it/logo_900_2.jpg",
                    position: "float-top-center",
                    rejectButtonCaptionColor: "white",
                    rejectButtonColor: "#DBBE6F",
                    rejectButtonDisplay: true,
                    textColor: "black",
                    },
                    callback: {
                          onPreferenceExpressedOrNotNeeded: function (preference) {
                            dataLayer.push({
                                iubenda_ccpa_opted_out: _iub.cs.api.isCcpaOptedOut(),
                            });
                            if (!preference) {
                                dataLayer.push({
                                      event: "iubenda_preference_not_needed",
                                });
                            } else {
                                if (preference.consent === true) {
                                      dataLayer.push({
                                        event: "iubenda_consent_given",
                                      });
                                } else if (preference.consent === false) {
                                      dataLayer.push({
                                        event: "iubenda_consent_rejected",
                                      });
                                } else if (preference.purposes) {
                                      for (var purposeId in preference.purposes) {
                                        if (preference.purposes[purposeId]) {
                                            dataLayer.push({
                                                  event: "iubenda_consent_given_purpose_" + purposeId,
                                            });
                                        }
                                      }
                                }
                            }
                          },
                    },
                };
                `}
            </script>
            <script type="text/javascript" src="//cdn.iubenda.com/cs/ccpa/stub.js"></script>
            <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async></script>
        </Helmet>
        {isWideScreen ? (

          <>
          {/* Desktop */}
            <div id="tolstoy-container" className="bg-black" style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
              <iframe id="tolstoy" src="https://player.gotolstoy.com/5qm7tvjkuthfv?host"
                style={{
                  width: "100%",
                  height: "100%",
                  border: "0px",
                  backgroundColor: "transparent",
                }}                      
                scrolling="no" 
                frameborder="0" 
                allow="autoplay *; clipboard-write *;camera *; microphone *; encrypted-media *; fullscreen *; display-capture *;">
              </iframe>
            </div>

          </>

        ) : (
          <>
          {/* Mobile */}
          <div id="tolstoy-container" className="bg-black" style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
            <iframe
              id="tolstoy"
              src="https://player.gotolstoy.com/vrh9osws0qskb?host"
              className=""
              style={{
                width: "100%",
                height: "100%",
                border: "0px",
                backgroundColor: "transparent",
              }}
              scrolling="no"
              frameBorder="0"
              allow="
                autoplay *;
                clipboard-write *;
                camera *;
                microphone *;
                encrypted-media *;
                fullscreen *;
                display-capture *;"
            >
            </iframe>

          </div>
    
          </>
        )}
    </div>

    <script src="https://widget.gotolstoy.com/script.js" defer></script>


    </> 
  )
}

export default Tolstoy