import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Tolstoy from "./landing/goldenTram/Tolstoy";
import ContactFormClienti from "./landing/goldenTram/ContactFormClienti";
import ContactFormCollaboratori from "./landing/goldenTram/ContactFormCollaboratori";
import "./index.css";


function App() {

  
  return (
    <div className="App">
        <Router>
            <Routes>
              {/* main routes */}
                <Route path="/*" element={<Navigate to="/golden-tram" replace />} />
                <Route exact path="/golden-tram" element={<Tolstoy/>} />
                <Route exact path="/golden-tram/consulenza-gratuita" element={<ContactFormClienti/>} />
                <Route exact path="/golden-tram/collabora-con-noi" element={<ContactFormCollaboratori/>} />
            </Routes>   
        </Router>
    </div>
  );
}

export default App;
